<template>
    <v-card class="card" flat :style="{ 'background': $vuetify.theme.themes.light.background }">
        <section class="d-flex justify-center">
            <v-container class="d-flex justify-space-between align-center px-1 mx-1">
                <v-img max-width="117" :src="logoURI" />
                <v-btn depressed text color="primary" class="btn-take-out d-flex justify-space-between" @click.stop="$emit('on-takeout')">
<!--                    <v-img max-width="64" src="@/assets/img/lang/takeout.svg" />-->
                  <svg width="167" height="167" viewBox="0 0 167 167" fill="none" xmlns="http://www.w3.org/2000/svg" style="max-width: 64px; width: 100%">
                    <path d="M155.996 52.0944L139.348 35.4455V17.0045C139.348 8.82346 132.739 2.03223 124.375 2.03223H32.508C24.3105 2.03223 17.5358 8.65701 17.5358 17.0045V27.2118C17.5358 27.4151 17.1921 34.6422 23.7987 39.3899L11.0946 52.094C5.37484 57.8132 2.22461 65.4183 2.22461 73.5081V159.909C2.22461 162.54 4.35801 164.674 6.9895 164.674H160.101C162.733 164.674 164.866 162.54 164.866 159.909V73.5081C164.866 65.4183 161.716 57.8132 155.996 52.0944ZM118.933 17.0045C118.933 14.0064 121.359 11.562 124.375 11.562C127.38 11.562 129.817 13.9943 129.817 17.0045V27.2118C129.817 30.2102 127.391 32.6543 124.375 32.6543C121.371 32.6543 118.933 30.2219 118.933 27.2118V17.0045ZM27.0656 17.0045C27.0656 14.0061 29.4918 11.562 32.508 11.562H110.425C109.765 13.2481 109.403 15.0823 109.403 16.9994V27.2118C109.403 29.1041 109.752 30.943 110.418 32.6543H32.508C29.5033 32.6543 27.0656 30.2219 27.0656 27.2118V17.0045ZM104.299 73.5081V155.144H11.7544V73.5081C11.7544 67.964 13.9129 62.7525 17.8328 58.8329L34.4816 42.1843H123.079L113.169 52.094C107.449 57.8132 104.299 65.4183 104.299 73.5081ZM155.336 155.144H113.829V73.5081C113.829 67.964 115.987 62.7525 119.907 58.8329L134.582 44.158L149.258 58.8332C153.178 62.7521 155.336 67.964 155.336 73.5081V155.144Z" :fill="$vuetify.theme.themes.light.primary" :stroke="$vuetify.theme.themes.light.primary" stroke-width="3"/>
                    <path d="M58.0227 78.5859C44.1384 78.5859 32.8428 89.8816 32.8428 103.766C32.8428 117.65 44.1384 128.946 58.0227 128.946C71.9069 128.946 83.2026 117.65 83.2026 103.766C83.2026 89.8816 71.9066 78.5859 58.0227 78.5859ZM58.0227 119.416C49.3931 119.416 42.3725 112.395 42.3725 103.766C42.3725 95.136 49.3931 88.1157 58.0227 88.1157C66.6522 88.1157 73.6728 95.1363 73.6728 103.766C73.6728 112.395 66.6519 119.416 58.0227 119.416Z" :fill="$vuetify.theme.themes.light.primary" :stroke="$vuetify.theme.themes.light.primary" stroke-width="3"/>
                  </svg>
                  <span class="mx-8">{{ translate(getOrderType, getOrderType).toUpperCase().replace('-', ' ') }}</span>
                  <v-icon size="69" color="primary" class="btn-take-out-icon">mdi-pencil-circle</v-icon>
                </v-btn>
                <v-btn @click.stop="$emit('confirm-cart-reset')" depressed color="primary" class="btn-reset">{{ translate('clear','CLEAR').toUpperCase() }}</v-btn>
            </v-container>
        </section>
    </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import translationMixin from '@/mixins/translations'
export default {
    name:'CartHeader',
    mixins: [translationMixin],
    computed: {
        ...mapGetters({
            getOrderType: 'app/getOrderType',
            menus: 'cart/getMenu',
            concept: 'concept/getConcept'
        }),
        logoURI() {
        return this.concept['logo-uri']
        },
    }
}
</script>
<style lang="scss" scoped>
.card{
    height: 187.77px;
    // background: #E5E5E5;
    // box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    // 0px 16px 24px rgba(0, 0, 0, 0.04),
    //  0px 4px 8px rgba(0, 0, 0, 0.04),
    //   0px 0px 1px rgba(0, 0, 0, 0.04) !important;
      section{
          height: 100% !important;
          .container{
              height: 100% !important;
              max-width: 1000px !important;
              .btn-take-out{
                //   width: 317px;
                  height: 117px;
                  background: #FFFFFF;
                  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
                  border-radius: 20px;
                  font-weight: bold;
                  font-size: 34px;
                  text-transform: capitalize;
                  &-icon{

                  }
              }
              .btn-reset{
                  width: 163px;
                  height: 86.91px;
                  border-radius: 178px;
                  font-weight: 900;
                  font-size: 24px;
                  color: #FFFFFF;
              }
        }
    }
}
</style>
